<template>
  <b-modal
    v-model="show"
    title="Project Detail"
    title-tag="div"
    body-class="p-0"
    no-fade
    footer-class="d-flex justify-content-between"
    centered
    @change="$emit('toggle-modal')"
  >
    <div class="d-block pr-2 pl-2 pt-1">
      <b-row>
        <b-col cols="12">
          <h4>
            {{ projectDetail.projectName }}
          </h4>
          <p class="font-small-3">
            {{ projectDetail.customerName }}
            <b-badge variant="light-info">
              {{ projectDetail.businesName }}
            </b-badge>
          </p>
        </b-col>
        <b-col cols="12">
          {{ projectDetail.projectDescription }}
        </b-col>
        <b-col cols="6">
          <h6 class="pt-2">
            Project start:
          </h6>
          {{ projectDetail.projectStart }}

          <h6 class="pt-2">
            Team Owner:
          </h6>
          {{ projectDetail.teamOwner }}

          <h6 class="pt-2">
            Partner:
          </h6>
          {{ projectDetail.partner }}
        </b-col>
        <b-col cols="6">
          <h6 class="pt-2">
            <feather-icon icon="Edit2Icon" /> Programming Language:
          </h6>
          {{ projectDetail.programmingLanguage }}

          <h6 class="pt-2"><feather-icon icon="DatabaseIcon" /> Database:</h6>
          {{ projectDetail.database }}

          <h6 class="pt-2">
            <feather-icon icon="ToolIcon" /> Developement Tools:
          </h6>
          {{ projectDetail.devTool }}
        </b-col>
      </b-row>
    </div>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          text="Close"
          variant="secondary"
          class="float-right"
          size="sm"
          @click="$emit('toggle-modal')"
        >
          <feather-icon icon="XCircleIcon" />
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BRow,
    BCol,
    BBadge,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    projectDetail: {
      required: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  watch: {
    isShowModal: {
      handler(isShow) {
        if (isShow) {
          this.show = true
        } else {
          this.show = false
        }
      },
      immediate: true,
    },
  },
}
</script>

<style></style>
