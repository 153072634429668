<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col xl="3" md="6">
        <b-card>
          <b-row>
            <b-col cols="8">
              <h1>{{ totalProject }}</h1>
              Total Project
            </b-col>
            <b-col cols="4">
              <h1 class="float-right">
                <b-img
                  :src="require('@/assets/images/flatIcon/suitcase.png')"
                  width="60px"
                />
              </h1>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col xl="3" md="6">
        <b-card>
          <b-row>
            <b-col cols="8">
              <h1>{{ totalCustomer }}</h1>
              Total Customer
            </b-col>
            <b-col cols="4">
              <h1 class="float-right">
                <b-img
                  :src="require('@/assets/images/flatIcon/people.png')"
                  width="60px"
                />
              </h1>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col xl="3" md="6">
        <b-card>
          <b-row>
            <b-col cols="8">
              <h1>{{ totalBusinessType }}</h1>
              Total Business Type
            </b-col>
            <b-col cols="4">
              <h1 class="float-right">
                <b-img
                  :src="require('@/assets/images/flatIcon/skyscraper.png')"
                  width="60px"
                />
              </h1>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col xl="3" md="6">
        <b-card>
          <b-row>
            <b-col cols="8">
              <h1>{{ totalPartner }}</h1>
              Total Partner
            </b-col>
            <b-col cols="4">
              <h1 class="float-right">
                <b-img
                  :src="require('@/assets/images/flatIcon/partner1.png')"
                  width="60px"
                />
              </h1>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" md="12">
        <dashboard-newest-project
          :newes-projects="newesProjects"
          @project-detail-id="showProjectDetail"
        />
      </b-col>
      <b-col xl="6" md="12">
        <dashboard-last-update-project
          :last-update-projects="lastUpdateProjects"
          @project-detail-id="showProjectDetail"
        />
      </b-col>
    </b-row>

    <dashboard-project-detail-modal
      :is-show-modal="isShowModal"
      :project-detail="projectDetail"
      @toggle-modal="showProjectDetail"
    />
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg,
} from 'bootstrap-vue'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import DashboardNewestProject from './DashboardNewestProject.vue'
import DashboardLastUpdateProject from './DashboardLastUpdateProject.vue'
import DashboardProjectDetailModal from './DashboardProjectDetailModal.vue'

axios.defaults.headers.common = {
  Authorization: `Bearer ${useJwt.getToken()}`,
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    DashboardNewestProject,
    DashboardLastUpdateProject,
    DashboardProjectDetailModal,
  },
  data() {
    return {
      data: {},
      totalProject: 0,
      totalCustomer: 0,
      totalBusinessType: 0,
      totalPartner: 0,
      isShowModal: false,
      newesProjects: [],
      lastUpdateProjects: [],
      projectDetail: {},
    }
  },
  created() {
    axios
      .get(`${this.$webApi}/api/Dashboard/GetAmountTotalProject`)
      .then(response => {
        this.totalProject = response.data.data.totalProject
        this.totalCustomer = response.data.data.totalCustomer
        this.totalBusinessType = response.data.data.totalBusinessType
        this.totalPartner = response.data.data.totalPartner
      })
      .catch(error => console.log(error))

    axios
      .get(`${this.$webApi}/api/Dashboard/GetNewestProjects`)
      .then(response => {
        this.newesProjects = response.data
      })
      .catch(error => console.log(error))

    axios
      .get(`${this.$webApi}/api/Dashboard/GetLatestUpdateProjects`)
      .then(response => {
        this.lastUpdateProjects = response.data
      })
      .catch(error => console.log(error))
  },
  methods: {
    showProjectDetail(projectId) {
      this.isShowModal = !this.isShowModal
      if (this.isShowModal) {
        const params = { projectId }
        axios
          .get(`${this.$webApi}/api/Dashboard/GetProjectDetail`, {
            params,
          })
          .then(response => {
            this.projectDetail = response.data
          })
          .catch(error => console.log(error))
      } else {
        this.projectDetail = {}
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
