<template>
  <b-row>
    <b-col>
      <b-card no-body>
        <b-card-header>
          <b-card-title>Newest Projects</b-card-title>
        </b-card-header>
        <b-card-body class="card-custom">
          <div
            v-for="project in newesProjects"
            :key="project.projectId"
          >
            <b-media
              no-body
              class="mt-1"
            >
              <b-avatar
                :src="$webApi + '/' + project.coverPicture"
              />
              <b-media-body
                class="mr-2"
                style="padding-left: 0.7em;"
              >

                <b-row>
                  <b-col xl="7" md="12">
                     <b-link
                      class="align-self-center my-auto"
                      @click="$emit('project-detail-id', project.projectId)"
                    >
                      {{ project.projectName }}
                    </b-link>
                    <br />
                    <div class="font-small-1">
                      {{ project.customerName }}
                    </div>
                  </b-col>
                  <b-col xl="5" md="12">
                    <b-badge
                      variant="light-success"
                      :class="setPositionBadge()"
                    >
                      Added {{ project.createDate | formatDate }}
                    </b-badge>
                  </b-col>
                </b-row>
              </b-media-body>
            </b-media>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BAvatar,
  BBadge,

  BMediaBody,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BBadge,

    BMediaBody,
    BLink,
  },
  props: {
    newesProjects: {
      required: true,
    },
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      this.setPositionBadge()
    },
    setPositionBadge() {
      if (this.window.width >= 768) {
        return 'float-right'
      }
      return 'float-left'
    },
  },
}
</script>

<style>
.card-custom {
  height: 300px;
  overflow: auto;
}
</style>
